import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServiceProviderSpaceComponent } from './service-provider-space.component';
import { ScreeningOverviewComponent } from './screening-overview/screening-overview.component';
import { ComponentOverviewComponent } from './component-overview/component-overview.component';
import { ExchangePanelComponent } from './exchange-panel/exchange-panel.component';
import { WarehouseOverviewComponent } from './warehouse-overview/warehouse-overview.component';
import { Co2LightComponent } from './co2-light/co2-light.component';
import { LightResultComponent } from './co2-light/light-result/light-result.component';
//import { EditScreeningComponent } from './screening-overview/edit-screening/edit-screening.component';
import { HomeAdminComponent } from './home-admin/home-admin.component';
import { CompareFunctionComponent } from './screening-overview/compare-function/compare-function.component';
import { CompareProjectsComponent } from './compare-projects/compare-projects.component';
import { ReflowAcademyComponent } from './reflow-academy/reflow-academy.component';
import { ShareOverviewComponent } from './share-overview/share-overview.component';
import { CompanyAssesstmentOverviewComponent } from './company-assesstment-overview/company-assesstment-overview.component';
import { AssessmentDashboardComponent } from './company-assesstment-overview/assessment-dashboard/assessment-dashboard.component';
import { CalculationStepperComponent } from './company-assesstment-overview/calculation-stepper/calculation-stepper.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { SupplierGuard } from '../auth/supplier.guard';

const routes: Routes = [
  {
    path:"",
    component: ServiceProviderSpaceComponent,
    canActivate: [AuthGuard, SupplierGuard],
    children: [
      {
        path:"",
        loadChildren: () => import('./quantify/quantify.module').then(m => m.QuantifyModule)
      },
      {
        path:"quantify",
        loadChildren: () => import('./quantify/quantify.module').then(m => m.QuantifyModule)
      },
      { path: "", component: ScreeningOverviewComponent },
      { path: "components", component: ComponentOverviewComponent },
      { path: "exchange-panel", component: ExchangePanelComponent },
      { path: "warehouses", component: WarehouseOverviewComponent },
      { path: "co2", component: Co2LightComponent },
      { path: "co2-result", component: LightResultComponent },
      //{ path: "decarbonizer", component: ScreeningOverviewComponent },
      //{ path: "screening/:screeningId", component: EditScreeningComponent },
      { path: "homeAdmin", component: HomeAdminComponent },
      { path: "compare", component: CompareFunctionComponent },
      { path: "compareProjects", component: CompareProjectsComponent },
      { path: "reflow-academy", component: ReflowAcademyComponent },
      { path: "share", component: ShareOverviewComponent },
      { 
        path: "company-assessment", 
        component: CompanyAssesstmentOverviewComponent,
        children: [
          { path: "", component: AssessmentDashboardComponent },
          { 
            path: ":companyAssesssmentId", 
            component: CalculationStepperComponent,
          },
        ],
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServiceProviderSpaceRoutingModule { }
