<div class="climatehub-dahboard-container">
    <h2 class="hub-title">ClimateHub</h2>
    <div class="dashboard-container">
        <div class="sidebar-calculations-container">
            <p class="calculation-sidebar-header">Company Assessment Profile</p>
            <div class="calculation-history-container">
                <button class="add-new-calculation-btn" (click)="createNewCompanyAssessment(null)">
                    <img src="../../../../assets/start new calculation.svg" alt="">
                    <label>Add a new calculation</label>
                    <div>&nbsp;</div>
                </button>
                <div class="calculations-history-lister">
                    <ng-container *ngIf="showArchives==false" >
                        <ng-container *ngFor="let item of activeCompanies">
                            <div class="calculation-item-finished" (click)="selectCompAss(item)" [ngClass]="{'container-expanded': isContainerexpanded == true && item.id == selectedItemId}">
                                <div class="finished-item">  
                                    <div class="img-container">
                                        <img src="../../../../assets/green folder.svg" *ngIf="item?.finished==true" alt="">
                                        <img src="../../../../assets/gold folder.svg" *ngIf="item?.finished==false" alt="">
                                    </div>
                                        <div class="item-details">
                                            <p class="year-calculation-title">Year {{item.year}} calculation</p>
                                            <p class="sub-text">Company's Name : {{item.groupName}}</p>
                                            <p class="sub-text" *ngIf="item?.finished==true">Reported Calculation</p>
                                            <p class="sub-text" *ngIf="item?.finished==false">Continue Calculation</p>
                                        </div>
                                        <div class="img-container">
                                            <img src="../../../../assets/finished calculation.svg" *ngIf="item?.finished==true" alt=""> 
                                            <img src="../../../../assets/continue calculation.svg" *ngIf="item?.finished==false" alt="">
                                        </div>
                                </div>                
                                <div class="action-buttons-container">
                                    <button class="add-new-company btns" *ngIf="item.showSubCompanyAssessmentDtos.length == 0"  (click)="createNewCompanyAssessment(item)">Add new subcompany <i class="fa fa-plus" aria-hidden="true"></i></button>
                                    <button class="add-new-company btns add-new-company-nodash" (click)="toggleItemContainer(item.id)" *ngIf="item.showSubCompanyAssessmentDtos.length > 0">Subcompany details  
                                        <i class="fa" [ngClass]="{'fa-angle-down': !toggleStates.get(item.id), 'fa-angle-up': toggleStates.get(item.id)}" aria-hidden="true"></i>
                                    </button>
                                    <button class="edit-btn btns" (click)="editCompanyAssessment(item?.id)" routerLinkActive="router-link-active" routerLink="{{item.id}}" >edit <img src="../../../../assets/editassement.svg" alt=""></button>
                                    <button class="archive-btn btns" (click)="openArchiveModal(item?.id)">archive <img src="../../../../assets/archive-companyt-assessment.svg" alt=""></button>
                                </div>
                                <div *ngIf="toggleStates.get(item.id)" class="subcompany-container">
                                    <button class="add-subcompany-btn" (click)="createNewCompanyAssessment(item)"><i class="fa fa-plus" aria-hidden="true"></i> Add subcompany</button>
                                    
                                    <div class="calculation-item-finished" *ngFor="let subcompanyitem of item.showSubCompanyAssessmentDtos">
                                        <div class="finished-item">
                                            <div class="img-container">
                                                <img src="../../../../assets/green folder.svg" *ngIf="subcompanyitem?.finished==true" alt="">
                                                <img src="../../../../assets/gold folder.svg" *ngIf="subcompanyitem?.finished==false" alt="">
                                            </div>
                                            <div class="item-details">
                                                <p>Year {{subcompanyitem.year}} calculation</p>
                                                <p class="sub-text">Company's Name : {{subcompanyitem.companyName}}</p>
                                                <p class="sub-text" *ngIf="subcompanyitem?.finished==true">Reported Calculation</p>
                                                <p class="sub-text" *ngIf="subcompanyitem?.finished==false">Continue Calculation</p>
                                            </div>
                                            <div class="img-container">
                                                <img src="../../../../assets/finished calculation.svg" *ngIf="subcompanyitem?.finished==true" alt=""> 
                                                <img src="../../../../assets/loadingsubcompany.svg" *ngIf="subcompanyitem?.finished==false" alt=""> 
                                            </div>
                                        </div>
                                        <div class="action-buttons-container"> 
                                            <button class="edit-btn btns gray-border" (click)="editCompanyAssessment(subcompanyitem?.id)" routerLinkActive="router-link-active" routerLink="{{item.id}}">edit <img src="../../../../assets/editassement.svg" alt=""></button>
                                        </div>
                                    </div> 
                                </div>
                            </div> 
                        </ng-container>
                    </ng-container>


                    <ng-container  *ngIf="showArchives==true">
                        <div class="calculation-item-finished" *ngFor="let item of archivedCompanies" [ngClass]="{'container-expanded': isContainerexpanded == true && item.id == selectedItemId}">
                            <div class="finished-item">  
                                    <div class="img-container">
                                        <img src="../../../../assets/archive-folder.svg" alt="">
                                    </div>
                                    <div class="item-details">
                                        <p class="year-calculation-title">Year {{item.year}} calculation</p>
                                        <p class="sub-text">Company's Name : {{item.groupName}}</p>
                                        <p class="sub-text" *ngIf="item?.finished==true">Reported Calculation</p>
                                        <p class="sub-text" *ngIf="item?.finished==false">Continue Calculation</p>
                                    </div>
                                    <div class="img-container">
                                        &nbsp;
                                    </div>
                            </div>                
                            <div class="action-buttons-container">
                                <button class="sub-company-details-btn" (click)="toggleItemContainer(item?.id)">Subcompany details 
                                    <i class="fa" [ngClass]="{'fa-angle-down': !toggleStates.get(item.id), 'fa-angle-up': toggleStates.get(item.id)}" aria-hidden="true"></i>
                                </button>
                                <button class="restore-archive-btn" (click)="activateCompanyAssessment(item?.id)">Restore <img src="../../../../assets/archive-restore.svg" class="restore-archive-icon" alt=""></button>
                            </div>
                            <div *ngIf="toggleStates.get(item.id)" class="subcompany-container">
                                <div class="calculation-item-finished" *ngFor="let subcompany of item?.showSubCompanyAssessmentDtos">
                                    <div class="finished-item">
                                        <div class="img-container"> 
                                            <img src="../../../../assets/archive-folder.svg" alt="">
                                        </div>
                                        <div class="item-details">
                                            <p>Year {{subcompany?.year}} calculation</p>
                                            <p class="sub-text">Reported Calculation</p>
                                        </div>
                                        <div class="img-container"> 
                                            <button class="restore-archive-dark-btn">Restore <img src="../../../../assets/archive-restore.svg" class="restore-archive-icon" alt=""></button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div> 
                    </ng-container>
                </div>
                
            </div>
            
            <div class="show-archive" (click)="toggleArchive()" [ngClass]="{'back-from-archive': showArchives==true}">
                <img src="../../../../assets/archive-box.svg" alt="" *ngIf="showArchives==false">
                <img src="../../../../assets/goback.svg" alt="" *ngIf="showArchives==true">
                <label>{{showArchives==false?'Go to archived calculations':'Back to active calculations'}}</label>
            </div>
        </div>
        <div class="calculation-charts-container">
            <ng-template #tipContent>
                <div class="tooltip-template"  *ngIf="chartStats!=null">
                    <div class="tt-row" *ngFor="let item of hoveredItem; let idx=index">
                        <div class="left-value">
                            <div class="bullet" [ngStyle]="{'background-color': dataColors[5-(3+idx)]}"></div>
                            <label for="">Scope {{idx+1}}</label>
                        </div>
                        <div class="right-value">
                            <label for="" class="big">{{item | number : '1.2-2'}}</label>
                            <label for="" class="small">tCO2e</label>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="group-total-emission-container">
                <div class="intro-container">
                    <label class="title">Select the company and the year for the results</label>
                    <p class="description">After selecting the year or years and the companies or group of companies, you will see the total results, broken down by scope and categories, displayed below.</p>
                </div>
                <div class="years-filter-container">
                    <div ngbDropdown [autoClose]="'outside'">
                        <div class="dd-button dd-year" id="dropdownBasic1" ngbDropdownToggle display="static" (click)="toggleDropdown('years')">
                            <label class="dd-label pr-2">{{selectedYear==""?"Select year":(selectedYear=='all'?'All Years':(countMultipleYears==1?"Year "+selectedYear:"Multiple years"))}}</label>
                            <em class="fa fa-angle-down ml-3 no-margin-ic" *ngIf="!isDropdownOpen"></em>
                            <em class="fa fa-angle-up ml-3 no-margin-ic" *ngIf="isDropdownOpen"></em>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dd-years-container">
                            <ng-container *ngFor="let year of years">
                                <button ngbDropdownItem  (click)="selectYear(year)">
                                    <label for="">{{year}}</label>
                                    <img src="../../../../assets/check-green.svg" alt="" *ngIf="selectedYears[year]==true">
                                </button>
                            </ng-container>
                            <button ngbDropdownItem (click)="selectYear('all')" *ngIf="selectedYears['all']==true; else noToggleYears " >
                                <label for="">All years</label>
                                <img src="../../../../assets/check-green.svg" alt="" *ngIf="selectedYears['all']==true">
                            </button>
                            <ng-template #noToggleYears>
                                <button ngbDropdownItem (click)="selectYear('all')" ngbDropdownToggle>
                                    <label for="">All years</label>
                                    <img src="../../../../assets/check-green.svg" alt="" *ngIf="selectedYears['all']==true">
                                </button>
                            </ng-template>
                        </div>
                    </div>
                    <div style="width: 20px;"></div>
                    <div ngbDropdown #compdd="ngbDropdown" [autoClose]="'outside'">
                        <div class="dd-button dd-subcompany" id="dropdownBasic1" ngbDropdownToggle display="static" (click)="toggleDropdown('companies')">
                            <label class="dd-label" >{{isSelectAllCompanyAssessments==true?'All company assessments':(countMultipleCompanies==0?'Select company assessment':(countMultipleCompanies==1?lastFound?.groupName:'Multiple selection'))}}</label>
                            <em class="fa fa-angle-down ml-3 no-margin-ic" *ngIf="!isCompaniesDropdownOpen"></em>
                            <em class="fa fa-angle-up ml-3 no-margin-ic" *ngIf="isCompaniesDropdownOpen"></em>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dd-companyAssessments-container">
                            <ng-container *ngFor="let item of filterCompanyAssessments">
                                <button ngbDropdownItem (click)="selectCompanyAssessmentFilter(item);">
                                    <label for=""  class="company-lbl">{{item?.groupName}}</label>
                                    <img src="../../../../assets/check-green.svg" alt="" *ngIf="selectedCompanyAssessments[item?.id]==true">
                                </button>
                            </ng-container>
                            <button ngbDropdownItem (click)="selectCompanyAssessmentFilter('all');" *ngIf="selectedCompanyAssessments['all']==true; else noToggleCompanies" >
                                <label for="">All companies</label>
                                <img src="../../../../assets/check-green.svg" alt="" *ngIf="selectedCompanyAssessments['all']==true">
                            </button>
                            <ng-template #noToggleCompanies>
                                <button ngbDropdownItem (click)="selectCompanyAssessmentFilter('all');" ngbDropdownToggle>
                                    <label for="">All companies</label>
                                    <img src="../../../../assets/check-green.svg" alt="" *ngIf="selectedCompanyAssessments['all']==true">
                                </button>
                            </ng-template>
                        </div>
                    </div>
                    <div style="width: 20px;"></div>
                    <div class="dd-button submit-subcompany" (click)="getChartData()">
                        <label class="dd-label">Submit</label>
                    </div>
                </div>
            </div>
            <div class="emisions-year-charts-container mt-4 smaller-margin">
                <img src="../../../../assets/world animation gif.gif" alt="" class="image-loading-style" *ngIf="chartStats==null && showLoading == true">
                <div class="titles-container">
                    <label class="emission-label" [ngClass]="{'disabled':chartStats==null}">{{chartTitle}}</label>
                    <label class="emission-value" [ngClass]="{'disabled':chartStats==null}">{{chartStats!=null? (chartStats?.totalCarbonFootPrint/1000 | number : '1.2-2') :''}} tCO2e</label>
                </div>
                <div class="scales-container">
                    <ng-container *ngIf="chartStats!=null">
                        <div class="scale-element" *ngFor="let item of ['#123B63','#8CBBDF','#8CD8D5'];let idx=index">
                            <div class="bullet" [ngStyle]="{'background-color': item}"></div>
                            <label class="scale-label" [ngClass]="{'disabled':chartStats==null}">Scope {{idx+1}}</label>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="chartStats==null">
                        <div class="scale-element" *ngFor="let item of fakeDataColors;let idx=index">
                            <div class="bullet" [ngStyle]="{'background-color': item}"></div>
                            <label class="scale-label" [ngClass]="{'disabled':chartStats==null}">Scope {{idx+1}}</label>
                        </div>
                    </ng-container>
                </div>
                <div class="chart-container ">
                    <div class="bars-container bars-container-parent" [ngStyle]="{'height': chartStats==null?'103.5%':''}" #parent (mousedown)="startDragging($event, false)" (mouseup)="stopDragging($event, false)" (mouseleave)="stopDragging($event, false)" (mousemove)="moveEvent($event)">
                        <span style="height: 98%; width: 1000px; display: flex; align-items: end; "  *ngIf="chartStats!=null">
                            
                                <ng-container *ngFor="let item of chartStats?.yAxesData;let idx1=index">
                                    <div class="bar-item-with-label">
                                        <div class="bar-element" (click)="selectBarChart(item)" [ngStyle]="{'height': (item?.totalCarbonFootprint*100)/maxValue+'%'}" (mouseenter)="hoverBarItem(item?.scopeImpactDistributions);" [ngbTooltip]="tipContent" data-toggle="tooltip" placement="top">
                                            <div class="bar-shadow" [ngStyle]="{'background-color': (selectedBarchartItem==item?'#F3F4F9':''),'border': (selectedBarchartItem==item?'solid 2px black':'')}"></div>
                                            <div class="bar-fragment" [ngStyle]="{'height': item?.scopeImpactDistributions[0].scopeImpact == 0?0:item?.scopeImpactDistributions[0].scopeImpactPercentage+'%', 'background-color':'#123B63'}"></div>
                                            <div class="bar-fragment" *ngIf="item?.scopeImpactDistributions[1].scopeImpactPercentage>0==true" [ngStyle]="{'height': item?.scopeImpactDistributions[1].scopeImpact == 0?0:item?.scopeImpactDistributions[1].scopeImpactPercentage+'%', 'background-color':'#8CBBDF'}"></div>
                                            <div class="bar-fragment" *ngIf="item?.scopeImpactDistributions[2].scopeImpactPercentage>0==true" [ngStyle]="{'height': item?.scopeImpactDistributions[2].scopeImpact == 0?0:item?.scopeImpactDistributions[2].scopeImpactPercentage+'%', 'background-color':'#8CD8D5'}"></div>
                                        </div>
                                        <label class="year" [ngClass]="{'disabled':chartStats==null}">{{chartStats?.xAxesLabels[idx1]}}</label>
                                        <div style="height: 15px !important;"></div>
                                    </div>
                                </ng-container>
                        </span>
                        <ng-container *ngIf="chartStats==null">
                            <div class="bar-element" *ngFor="let item of fakeEmissions">
                                <div [ngbTooltip]="tipContent" (mouseenter)="hoverBarItem(item);" data-toggle="tooltip" placement="top" class="bar-fragment" [ngStyle]="{'height': item[0]+'%', 'background-color':fakeDataColors[0]}"></div>
                                <div [ngbTooltip]="tipContent" (mouseenter)="hoverBarItem(item);" data-toggle="tooltip" placement="top" class="bar-fragment" [ngStyle]="{'height': item[1]+'%', 'background-color':fakeDataColors[1]}"></div>
                                <div [ngbTooltip]="tipContent" (mouseenter)="hoverBarItem(item);" data-toggle="tooltip" placement="top" class="bar-fragment" [ngStyle]="{'height': item[2]+'%', 'background-color':fakeDataColors[2]}"></div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="horisentals-container">
                        <div class="back-hr" *ngFor="let item of yAxisAttributes">
                            <label [ngClass]="{'disabled':chartStats==null}">{{item | number : '1.0-0'}} tCO<sub>2</sub>e</label>
                            <div class="hr"></div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="group-total-emission-container mt-4" *ngIf="false">
                <div class="intro-container">
                    <label class="title" [ngClass]="{'disabled':chartStats==null}">Select the subcompany</label>
                    <p class="description" [ngClass]="{'disabled':chartStats==null}">Download comprehensive insights into your carbon emissions, understand your environmental impact across Scopes 1, 2, and 3.</p>
                </div>
                <div class="years-filter-container">
                    <div ngbDropdown>
                        <div class="dd-button dd-subcompany-2" id="dropdownBasic1" ngbDropdownToggle display="static">
                            <label class="dd-label"  [ngClass]="{'disabled':chartStats==null}">{{selectedSubCompanyAssessment==null?'Select Subcompany':selectedSubCompanyAssessment?.companyName}}</label>
                            <em class="fa fa-angle-down ml-3"></em>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <ng-container *ngFor="let subCompanyAssessment of selectedCompanyFilter?.showSubCompanyAssessmentDtos">
                                <button ngbDropdownItem (click)="selectSubCompanyAssessment(subCompanyAssessment)"  [ngClass]="{'disabled':chartStats==null}">{{subCompanyAssessment?.companyName}}</button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>


            <div class="charts-row-container mt-4 smaller-margin">
                <div class="total-cf-container">
                    <p class="tcf-header" [ngClass]="{'disabled':chartStats==null}">Total carboon footprint</p>
                    <span class="tcf-value" [ngClass]="{'disabled':chartStats==null}">{{selectedTotalCarbonFootprint!=null ? (selectedTotalCarbonFootprint/1000 | number : '1.2-2') :''}}</span><span class="tcf-unit" [ngClass]="{'disabled':chartStats==null}"> tCO2e</span>
                    <p class="tcf-label" [ngClass]="{'disabled':chartStats==null}">Emission scopes breakdown</p>
                    <div class="bar-container">
                        <div class="scopes-container smaller-container">
                        <div class="circle dark-circle"[ngStyle]="{'background-color': chartStats==null?'#D9DCE3':''}">&nbsp;</div>
                        <span class="spn-value" [ngClass]="{'disabled':chartStats==null}">&nbsp; Scope 1</span>
                        </div>
                        <div class="item-bar-container">
                            <div class="bar-item dark-circle" [ngStyle]="{'width':scopeImpactDistributions[0]?.scopeImpactPercentage+'%','background-color': chartStats==null?'#D9DCE3':''}" >&nbsp;</div>
                        </div>
                        <p class="spn-value sc-percentage"  [ngClass]="{'disabled':chartStats==null}">{{scopeImpactDistributions!=[]?(scopeImpactDistributions[0]?.scopeImpactPercentage| number : '1.2-2'):''}}%</p>
                    </div>
                    <div class="bar-container">
                        <div class="scopes-container smaller-container">
                        <div class="circle light-blue-circle" [ngStyle]="{'background-color': chartStats==null?'#D9DCE3':''}">&nbsp;</div>
                        <span class="spn-value"  [ngClass]="{'disabled':chartStats==null}">&nbsp; Scope 2</span>
                        </div>
                        <div class="item-bar-container">
                            <div class="bar-item light-blue-circle"  [ngStyle]="{'width':scopeImpactDistributions[1]?.scopeImpactPercentage+'%','background-color': chartStats==null?'#D9DCE3':''}" >&nbsp;</div>
                        </div>
                        <p class="spn-value sc-percentage"  [ngClass]="{'disabled':chartStats==null}">{{scopeImpactDistributions!=[]?(scopeImpactDistributions[1]?.scopeImpactPercentage| number : '1.2-2'):''}}%</p>
                    </div>
                    <div class="bar-container">
                        <div class="scopes-container smaller-container">
                        <div class="circle light-circle"[ngStyle]="{'background-color': chartStats==null?'#D9DCE3':''}">&nbsp;</div>
                        <span class="spn-value"  [ngClass]="{'disabled':chartStats==null}">&nbsp; Scope 3</span>
                        </div>
                        <div class="item-bar-container">
                            <div class="bar-item light-circle"  [ngStyle]="{'width':scopeImpactDistributions[2]?.scopeImpactPercentage+'%','background-color': chartStats==null?'#D9DCE3':''}">&nbsp;</div>
                        </div>
                        <p class="spn-value sc-percentage"  [ngClass]="{'disabled':chartStats==null}">{{scopeImpactDistributions!=[]?(scopeImpactDistributions[2]?.scopeImpactPercentage| number : '1.2-2'):''}}%</p>
                    </div>

                </div>
                <div class="top-emission-container">
                    <p class="tcf-header"  [ngClass]="{'disabled':chartStats==null}">Top emissions by categories</p>
                    <div class="emission-header-container">
                        <div class="dropdown-holder">
                            <div class="sc-values-container" (click)="toggleContainer()">
                                <div class="circle dark-circle" [ngStyle]="{'background-color': chartStats==null?'#D9DCE3':''}" *ngIf="selectedFiterScope == 'Scope 1'">&nbsp;</div>
                                <div class="circle light-blue-circle" *ngIf="selectedFiterScope == 'Scope 2'">&nbsp;</div>
                                    <div class="circle light-circle" *ngIf="selectedFiterScope == 'Scope 3'">&nbsp;</div>
                                &nbsp;<label  [ngClass]="{'disabled':chartStats==null}"> {{ selectedFiterScope==''?'Scope':selectedFiterScope }}</label>
                                <em [ngClass]="{'icon-arrow-down': !isContainerVisible, 'icon-arrow-up': isContainerVisible}"></em>
                            </div> 
                            <div class="sc-container" [style.zIndex]="9999" *ngIf="isContainerVisible==true && chartStats!=null">
                                <div class="sc-value" style="border-top-right-radius: 8px !important;border-top-left-radius: 8px !important;" (click)="selectFilterScope('Scope 1')" [ngClass]="{'selected-scope': selectedFiterScope == 'Scope 1'}"><div class="circle dark-circle" [ngClass]="{'disabled':chartStats==null}">&nbsp;</div>&nbsp; Scope 1</div> 
                                <div class="sc-value" (click)="selectFilterScope('Scope 2')" [ngClass]="{'selected-scope': selectedFiterScope == 'Scope 2'}"><div class="circle light-blue-circle" [ngClass]="{'disabled':chartStats==null}">&nbsp;</div>&nbsp; Scope 2</div> 
                                <div class="sc-value" (click)="selectFilterScope('Scope 3')" [ngClass]="{'selected-scope': selectedFiterScope == 'Scope 3'}"><div class="circle light-circle" [ngClass]="{'disabled':chartStats==null}">&nbsp;</div>&nbsp; Scope 3</div> 
                            </div>
                        </div>
                        <div>
                    <span class="tcf-header smaller-tcf" [ngClass]="{'disabled':chartStats==null}"> {{ selectedFiterScope==''?'Scope':selectedFiterScope }} : </span>
                    <span class="tcf-header" [ngClass]="{'disabled':chartStats==null}">{{scopeImpactDistributions!=null?(scopeImpactDistributions[(selectedFiterScope!="Scope 1"?(selectedFiterScope!="Scope 2"?2:1):0)]?.scopeImpact/1000 | number : '1.2-2'):'' }} tCO2e</span>
                        </div>  
                    </div>
                    <ng-container  > 
                        <ng-container *ngFor="let categoryEmission of showCategoryImpactDistributions;let idx=index" style="margin-top: 10%;">
                            <div class="bar-container full-width" *ngIf="idx<5">
                                <div class="scopes-container"> 
                                <p class="spn-value bar-text" [ngClass]="{'disabled':chartStats==null}">&nbsp; {{categoryEmission.categoryName}}</p>
                                </div>
                                <div class="item-bar-container item-bar-container-em">
                                    <div class="bar-item dark-circle" *ngIf="chartStats!=null" [ngStyle]="{'width': categoryEmission.categoryImpactPercentage+'%','background-color': (selectedFiterScope=='Scope 1'?dataColors[2]:(selectedFiterScope=='Scope 2'?dataColors[1]:dataColors[0]))}" >&nbsp;</div>
                                    <div class="bar-item dark-circle" *ngIf="chartStats==null" [ngStyle]="{'width': categoryEmission.categoryImpactPercentage+'%'}" style="background-color: rgb(217, 220, 227);">&nbsp;</div>
                                </div>
                                <span class="spn-value em-value" [ngClass]="{'disabled':chartStats==null}">{{categoryEmission.categoryImpactPercentage}}%</span><span class="spn-unit" [ngClass]="{'disabled':chartStats==null}">{{categoryEmission.categoryImpact/1000 | number : '1.2-2'}} tCO2e</span>
                            </div>
                        </ng-container> 
                    </ng-container>
                    <ng-container *ngIf="selectedFiterScope=='' && false">
                        <ng-container *ngFor="let item of emptyEmissions;let idx=index">
                            <div class="bar-container full-width" *ngIf="idx<5-showCategoryImpactDistributions.length">
                                <div class="scopes-container"> 
                                <p class="spn-value bar-text" [ngClass]="{'disabled':chartStats==null}">&nbsp;</p>
                                </div>
                                <div class="item-bar-container item-bar-container-em">
                                    <div class="bar-item dark-circle" style="width: 2%;background-color: rgb(217, 220, 227);">&nbsp;</div>
                                </div>
                                <span class="spn-value em-value" [ngClass]="{'disabled':chartStats==null}">0%</span><span class="spn-unit" [ngClass]="{'disabled':chartStats==null}">{{item?.impact | number : '1.2-2'}} tCO2e</span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="cf-report-container" *ngIf="!showCalculations">
                <div class="p-container">
                    <p class="report-title" [ngClass]="{'disabled':chartStats==null}">Carbon footprint reports</p>
                    <p class="report-description-text" [ngClass]="{'disabled':chartStats==null}">Download comprehensive insights into your carbon emissions, understand your environmental impact across Scopes 1, 2, and 3.</p>
                </div>
                <div class="cf-download-buttons">
                    <div style="margin-top: 20px;" (click)="downloadSummary(selectedBarchartItem)">
                        <button class="button-download-sammary" [ngClass]="{'disabled':chartStats==null}">Download summary <img src="../../../../assets/svgviewer-png-output download report.png" style="width: 35px;height: 33.58px;" alt=""></button>
                    </div>
                    <div style="margin-top: 20px;">
                        <button class="button-download-full-report">Download full report <img src="../../../../assets/svgviewer-png-output download full report cor.png" style="width: 35px;height: 33.58px;" alt=""></button>
                    </div>
                </div>
            </div>
            <div class="cf-report-container" *ngIf="showCalculations">
                <div class="p-container">
                    <p class="report-title" [ngClass]="{'disabled':chartStats==null}">Carbon footprint reports</p>
                    <p class="report-description-text" [ngClass]="{'disabled':chartStats==null}">Download comprehensive insights into your carbon emissions, understand your environmental impact across Scopes 1, 2, and 3.</p>
                </div>
                <div class="cf-download-buttons">
                    <div style="margin-top: 20px;" class="d-sammary-container">
                        <button class="button-download-sammary" [ngClass]="{'disabled':chartStats==null}" (click)="downloadSummary(selectedBarchartItem)">
                            Download summary 
                            <img src="../../../../assets/svgviewer-png-output download report active.png" style="width: 35px;height: 33.58px;" alt="" *ngIf="chartStats!=null">
                            <img src="../../../../assets/svgviewer-png-output download report.png" style="width: 35px;height: 33.58px;" alt="" *ngIf="chartStats==null">
                        </button>
                    </div>
                    <div style="margin-top: 20px;" class="d-fullreport-container">
                        <button class="button-download-full-report" style="color: #91959F;" [ngClass]="{'disabled':chartStats==null}">Download full report <img src="../../../../assets/svgviewer-png-output download full report cor.png" style="width: 35px;height: 33.58px;" alt=""></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="openModal-about" class="modalDialog" *ngIf="showArchiveModal">
    <div class="re-modal-content">
        <div>
            <em class="icon-close close-responsive" (click)="closeArchiveModal()"></em>
        </div>
        <div class="header-container">
            <div>
            <img src="../../../../assets/yellow-box.svg"> 
            </div>
            <p class="Warning-title">Confirm archiving calculation</p>
            <div class="modal-cnt">
                <p class="above-text">You are about to archive a calculation for your company. This action will move the selected calculation from the active list to the archive. You can view and restore archived calculations at any time from the archive section.</p>
            </div>
            <div class="modal-btn-container">
                <button class="proceed-button"  (click)="closeArchiveModal()">Go Back and Complete</button>
                <button class="go-back-button"  (click)="archiveCompanyAssessment()">Archive</button>
            </div>
        </div> 

    </div>
</div>

<div #divToCapture class="chart-content with-canvas"  id="pdf-snapshot-render" style=" position:absolute; z-index:-1;top: 0;">
    <canvas id="canvas" width="300" height="300"></canvas>
    <div class="text-inside-container">
        <label for="" class="simple">Total</label>
        <label for="" class="big">{{companyAssessmentResult?.totalCarbonFootprint/1000 | number : '1.2-2' }}</label>
        <label for="" class="simple">tCO<sub>2</sub>e</label>
    </div>
</div>

