import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { ComponentLog } from "../models/component-log";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class ComponentLogService {
  environmentUrl = "Debug api";

  constructor(
    private http: HttpClient,
     private router: Router,
     private helperService:HelperService,
     ) {
    this.environmentUrl = environment.apiUrl;
  }

  public getComponentLogs(componentId: number): any {
    
    return this.http.get<any>(`${this.environmentUrl}/api/ComponentLogs/` + componentId,this.helperService.headers());
  }

  public addComponentLog(componentLog: ComponentLog): any {
    
    return this.http.post<any>(
      `${this.environmentUrl}/api/ComponentLogs`,
      componentLog,
      this.helperService.headers()
    );
  }
}
