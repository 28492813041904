import { Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { CompanyAssessmentService } from '../../../services/company-assessment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyAssessmentDashboardService } from '../../../services/company-assessment-dashboard.service';
import { Chart } from 'chart.js';
import html2canvas from 'html2canvas';
import { ScreeningService } from '../../../services/screening.service';
import { ToastrService } from 'ngx-toastr';

//import * as scroller from "../../../../assets/js/side-scroll.js"

@Component({
  selector: 'app-assessment-dashboard',
  templateUrl: './assessment-dashboard.component.html',
  styleUrls: ['./assessment-dashboard.component.scss']
})
export class AssessmentDashboardComponent implements OnInit, OnChanges {

  @Output() goToCalculations=new EventEmitter();
  @Output() selectedCompanyAssessment=new EventEmitter();
  Math = Math;
  public showCalculations=false;
  public assessments:any=[];
  public loading=false;
  public isContainerVisible = false;
  public selectedItemId = 0;
  public isContainerexpanded = false;
  public selectedFiterScope = "";
  public showArchives=false;
  public showArchiveModal=false;
  public company_id:any=null;
  public companyAssessmentId:any=null;
  public archivedCompanies:any=null;
  public activeCompanies:any=null;
  public selectedYear="";
  public selectedCompanyFilter=null;
  public filterCompanyAssessments:any=[]
  public years=[];
  public chartStats:any=null;
  public dataColors=['#8CD8D5','#8CBBDF','#123B63'];
  public fakeDataColors=['#D9DCE3','#C4C5CB','#C0C1CBCC'];
  public hoveredItem:any=null;
  public selectedSubCompanyAssessment:any=null;
  public emissionsByCategories=[];
  public currentSubCompanyAssessment:any=null;
  public selectedItemToArchive;
  public scopeImpactDistributions:any[]=[];
  public categoryImpactDistributions:any[]=[];
  public showCategoryImpactDistributions:any[]=[];
  public selectedTotalCarbonFootprint=null;
  public maxValue=0;
  public chartTitle = "Total Emissions";
  public selectedBarchartItem:any=null;
  public toggleStates: Map<string, boolean> = new Map();
  public isDropdownOpen: boolean = false;
  public isCompaniesDropdownOpen: boolean = false;
  public showLoading = false;
  public companyAssessmentResult:any=null;
  constructor(
    private companyAssessmentService:CompanyAssessmentService,
    private router: Router,
    private route: ActivatedRoute,
    private companyAssessmentDashboardService:CompanyAssessmentDashboardService,
    private screeningService: ScreeningService,
    private toastrService:ToastrService,
  ) { }

  ngOnInit(): void {
    this.company_id=Number(localStorage.getItem("company_id"));
    localStorage.setItem('currentCompanyAssessment',"");
    
    this.getActiveCompanyAssessmentsByCompanyId();

    this.companyAssessmentService.getYears(this.company_id).subscribe(res=>{
      this.years=res;
    });
    
  }

  ngOnChanges(): void {
    this.getActiveCompanyAssessmentsByCompanyId();
  }

  public toggleArchive(){
    this.showArchives=!this.showArchives;
    if(this.showArchives==true){
      this.archivedCompanies=null;
      this.getArchivedCompanyAssessmentsByCompanyId()
    }else{
      this.activeCompanies=null;
      this.getActiveCompanyAssessmentsByCompanyId()
    }
  }

  

  public getActiveCompanyAssessmentsByCompanyId(){
    this.companyAssessmentDashboardService.getActiveCompanyAssessmentsByCompanyId(this.company_id).subscribe(ans=>{
      this.activeCompanies=ans;
      this.showCalculations=true;
    })
  }

  public getArchivedCompanyAssessmentsByCompanyId(){
    this.companyAssessmentDashboardService.getArchivedCompanyAssessmentsByCompanyId(this.company_id).subscribe(ans=>{
      this.archivedCompanies=ans;
    })
  }

  public archiveCompanyAssessment(){
    this.showArchiveModal = true;
    this.companyAssessmentDashboardService.archiveCompanyAssessment(this.selectedItemToArchive).subscribe(ans=>{
      this.getActiveCompanyAssessmentsByCompanyId();
    },err=>{
      this.getActiveCompanyAssessmentsByCompanyId();
    })
    this.showArchiveModal = false;
  }

  public activateCompanyAssessment(id){
    this.companyAssessmentDashboardService.activateCompanyAssessment(id).subscribe(ans=>{
      this.getArchivedCompanyAssessmentsByCompanyId();
    },err=>{
      this.getArchivedCompanyAssessmentsByCompanyId();
    })
  }
  public selectedYears:any={}
  public countMultipleYears=0;
  public lastFoundYear="";
  public tempComps:any=[];
  public selectCompaniesOfYear(year){
    this.tempComps=[];
    this.activeCompanies.forEach(company => {
      company['showSubCompanyAssessmentDtos'].forEach((subcompany) => {
        if(year=='all'&&subcompany['finished']==true){
          subcompany['groupName']=subcompany['companyName'];
          this.tempComps.push(subcompany);
        }else if(year==subcompany['year']&&subcompany['finished']==true){
          subcompany['groupName']=subcompany['companyName'];
          this.tempComps.push(subcompany);
        }
      });
      if(year=='all'&&company['finished']==true){
        this.tempComps.push(company);
      }else if(year==company['year']&&company['finished']==true){
        this.tempComps.push(company);
      }
    });
    return this.tempComps;
  }
  public selectYear(year){
    
    if(this.selectedYears[year]==true){
      if(year=='all'){
        this.filterCompanyAssessments=[];
        this.selectedYears['all']=false;
        this.selectedYear="";
        return;
      }
      this.selectedYears[year]=false;
      Object.keys(this.selectedCompanyAssessments).forEach((key)=>{
        if(this.selectedCompanyAssessments[key]==true&&key!='all'){
          if(this.filterCompanyAssessments.filter(x=>x.year==year)[0]!=null){
            this.selectedCompanyAssessments[key]=false;
          }
        }
      });
      this.countYears();
      this.filterCompanyAssessments=[]
      Object.keys(this.selectedYears).forEach((key)=>{
        if(this.selectedYears[key]==true && key!='all'){
          this.filterCompanyAssessments=this.filterCompanyAssessments.concat(this.selectCompaniesOfYear(key));
        }
      });
      
      return;
    }

    this.selectedYears['all']=false;
    if(year=='all'){
      
      this.chartTitle = "Total Emissions by Company";
      this.years.forEach(element => {
        this.selectedYears[element]=false;
      });
      this.filterCompanyAssessments=this.selectCompaniesOfYear(year);
      this.selectedYears['all']=true;
    }
    else{
      this.chartTitle = "Total Emissions by Year";
      if(this.selectedYears[year]==null){
        this.selectedYears[year]=true;
      }else{
        this.selectedYears[year]=!this.selectedYears[year]
      }
    }
    this.countYears();
    if(year=="all"&&this.selectedYears['all']==true){
      this.selectedYear='all';
    }
    if(year!="all"){
      this.filterCompanyAssessments=[]
      Object.keys(this.selectedYears).forEach((key)=>{
        if(this.selectedYears[key]==true && key!='all'){
          this.filterCompanyAssessments=this.filterCompanyAssessments.concat(this.selectCompaniesOfYear(key));
        }
      });
    }
    
  }

  public countYears(){
    this.countMultipleYears=0;
    Object.keys(this.selectedYears).forEach((key)=>{
      if(this.selectedYears[key]==true&&key!='all'){
        this.countMultipleYears++;
        this.lastFoundYear=key;
      }
    });

    if(this.countMultipleYears==0){
      this.selectedYear="";
    }else{
      this.selectedYear=this.lastFoundYear;
    }
  }

  public toggleCalculation(){
    this.goToCalculations.emit(false);
  }

  public selectCompAss(companyAssessment){
    this.selectedCompanyAssessment.emit(companyAssessment)
    localStorage.setItem('currentCompanyAssessmentId',companyAssessment.id);
  }

  public selectedCompanyAssessments:any={}
  public isSelectAllCompanyAssessments=false;
  public idsOfCompanyAssessments=[];
  public countMultipleCompanies=0;
  public lastFound:any=null;
  public selectCompanyAssessmentFilter(company){
    //this.selectedCompanyAssessments['all']=false;
    this.isSelectAllCompanyAssessments=false;
    if(company=='all'){
      if(this.selectedCompanyAssessments['all']==true){
        this.isSelectAllCompanyAssessments=false;
        this.selectedCompanyAssessments['all']=false;
      }else{
        this.isSelectAllCompanyAssessments=true;
        this.selectedCompanyAssessments['all']=true;
      }
      this.filterCompanyAssessments.forEach(element => {
        this.selectedCompanyAssessments[element?.id]=false;
      });
    }else{
      this.selectedCompanyAssessments['all']=false;
      if(this.selectedCompanyAssessments[company?.id]==null){
        this.selectedCompanyAssessments[company?.id]=true;
      }else{
        this.selectedCompanyAssessments[company?.id]=!this.selectedCompanyAssessments[company?.id];
      }
    }
    this.selectedCompanyFilter=company?.id;
    this.countMultipleCompanies=0;
    
    Object.keys(this.selectedCompanyAssessments).forEach((key)=>{
      if(this.selectedCompanyAssessments[key]==true&&key!='all'){
        this.countMultipleCompanies++;
        this.lastFound=key;
      }
    });

    if(this.countMultipleCompanies==1){
      this.lastFound=this.filterCompanyAssessments.filter(x=>x.id==this.lastFound)[0];
    }
    
  }

  public getChartData(){    
    
    this.showLoading = true;
    this.chartStats = null;
    let targetCompanies:any=[];
    let targetYears:any=[];
    let allyears=false;
    let allcompanies=false;
    
    if(this.selectedCompanyAssessments['all']==true){
      targetCompanies=[];
      targetCompanies=this.filterCompanyAssessments.map(a => a.id);
      allcompanies=true;
    }else{
      for(var k in this.selectedCompanyAssessments){
        if(this.selectedCompanyAssessments[k]==true){
          targetCompanies.push(k)
        }
      }
    }

    if(this.selectedYears['all']==true){
      targetYears=this.years;
      targetCompanies=this.activeCompanies.map(a => a.id);
      allyears=true;
    }else{
      for(var k in this.selectedYears){
        if(this.selectedYears[k]==true){
          targetYears.push(k)
        }
      }
    }
    
    if(targetCompanies.length==0||targetYears.length==0){
      this.toastrService.warning("Select year and company assessment !");
      this.showLoading = false;
      return
    }

    this.companyAssessmentDashboardService.getCompanyAssessmentsstats(this.company_id,
      {
        selectedYears:targetYears,
        selectedCompanies:targetCompanies,
        allYears:allyears,
        allCompanies:allcompanies
      }).subscribe(ans=>{
        this.chartStats=ans;
        this.maxValue=0;
        let takeYear:boolean = true;
        let toBeSelected:any=null;
        if(isNaN(ans?.xAxesLabels[0])==true){
          takeYear=false;
        }
        ans?.yAxesData.forEach(element => {
          if(takeYear==true){
            toBeSelected=element;
          }else{
            if(element?.totalCarbonFootprint>=this.maxValue){
              toBeSelected=element;
            }
          }
          this.maxValue=Math.max(this.maxValue,element?.totalCarbonFootprint);
        });
        this.bakeYaxisAttributes(this.maxValue);
        this.selectBarChart(toBeSelected);
      })


    
  }

  public goTotepper(data){
    this.router.navigate([data.id]);
  }

  public createNewCompanyAssessment(companyAssessment){
    this.loading=true;
    let createCompanyaAssessmentDto={
      includesMonthlyCalculation:false,
      selectedYear:null,
      companyId:Number(localStorage.getItem('company_id')),
      parentCompanyAssesmentId:(companyAssessment==null?0:companyAssessment?.id)
    };
    if(companyAssessment!=null){
      createCompanyaAssessmentDto.selectedYear=companyAssessment.year
    }
    this.companyAssessmentService.createCompanyAssessment(createCompanyaAssessmentDto).subscribe(ans=>{
      this.router.navigate([ans],{ relativeTo: this.route });
      this.loading=false;
    },err=>{
      
    })
  }

  public editCompanyAssessment(id){
    this.router.navigate([id],{ relativeTo: this.route });
  }

  toggleContainer() {
    this.isContainerVisible = !this.isContainerVisible;
  }

  public emptyEmissions=['','','','',''];
  public fakeEmissions=[[23,37,40],[20,20,60],[70,15,15],[33,33,33]];

  public selectFilterScope(selectedScope) {
    this.showCategoryImpactDistributions = this.categoryImpactDistributions.filter(x=>x.scope==selectedScope);
    this.isContainerVisible=false;
    this.selectedFiterScope=selectedScope;
  }

  toggleItemContainer(itemId) {
    this.toggleStates.forEach((value, key) => {
      if (key !== itemId) {
        this.toggleStates.set(key, false);
      }
    });
    this.isContainerexpanded = !this.isContainerexpanded;
    this.selectedItemId = itemId;
    this.toggleStates.set(itemId, !this.toggleStates.get(itemId));
  }

  public addSubcompanyAssessment(companyId){

  }

  public hoverBarItem(item){
    this.hoveredItem=[item[0].scopeImpact,item[1].scopeImpact,item[2].scopeImpact]
  }

  public max(a,b){
    if(isNaN(a)||a<1){
      return 1;
    }
    return Math.max(a,b);
  }

  public selectSubCompanyAssessment(subCompanyAssessment){
    this.selectedSubCompanyAssessment=subCompanyAssessment;
    
    this.currentSubCompanyAssessment=this.chartStats['subCompanyScopeDistribution'].filter(x=>x?.subCompanyName==this.selectedSubCompanyAssessment?.companyName)[0];
    this.selectFilterScope("1")
  }

  public closeArchiveModal()
  {
    this.showArchiveModal = false;
  }
  public openArchiveModal(id)
  {
    this.selectedItemToArchive = id
    this.showArchiveModal = true;
  }

  public selectBarChart(yAxesData)
  {
    this.selectedBarchartItem=yAxesData;
    this.scopeImpactDistributions = yAxesData.scopeImpactDistributions;
    this.categoryImpactDistributions = yAxesData.categoryImpactDistribution;
    this.selectedTotalCarbonFootprint=yAxesData?.totalCarbonFootprint;
    this.selectedFiterScope="Scope 1";
    this.selectFilterScope(this.selectedFiterScope);
    this.preDownloadSummary(this.selectedBarchartItem)
  }

  public yAxisAttributes=[0,0,0,0,0,0];

  public bakeYaxisAttributes(upperValue){
    let x:number=upperValue;
    let p=0;
    let k=10;
    while(x>=10){
      p++;
      x/=10;
    }
    x=Math.pow(10,p);
    let reg=x/2;
    while(x<upperValue){
      x+=reg;
    }
    this.maxValue=x;
    x=parseInt(Math.ceil(x/5)+"");
    x/=1000;
    this.yAxisAttributes=[x*5,x*4,x*3,x*2,x,0];
    
  }
  toggleDropdown(element) { 
    if(element == "years")
    {
          this.isDropdownOpen = !this.isDropdownOpen;
          this.isCompaniesDropdownOpen = false;
    }
    if(element == "companies")
    {
      this.isCompaniesDropdownOpen = !this.isCompaniesDropdownOpen;
      this.isDropdownOpen = false;
    }

  }
  @ViewChild("divToCapture") divToCapture: ElementRef;
  public chart: any;
  public usePieCart:boolean=false;
  public _companyassessmentId=null;
  public preDownloadSummary(companyAssessment){
    let idx=this.chartStats?.yAxesData.indexOf(companyAssessment)
    let _companyassessment=this.filterCompanyAssessments.filter(x=>x.groupName==this.chartStats?.xAxesLabels[idx])[0];
    this.companyAssessmentService.getCompanyAssessmentResult(_companyassessment['id']).subscribe(res=>{
      this._companyassessmentId=_companyassessment['id'];
      this.companyAssessmentResult=res;
      let ox:any[]=[];
      
      this.companyAssessmentResult['showCategoryImpactDtos']=ox.sort((a, b) => a.scope - b.scope);
      this.chart = new Chart('canvas', {
        type: 'doughnut',
        data: {
          labels: ["fzef","fz","ggr"],
          datasets: [
            { 
              data: [res.showScopeImpactDtos[0].impact,res.showScopeImpactDtos[1].impact,res.showScopeImpactDtos[2].impact],
              backgroundColor: ['#153C62','#77DDD9','#8CBBDF'],
              fill: false
            },
          ]
        },
        options: {
          aspectRatio: 1,
          legend: {
            display: false
          },
          tooltips:{
            enabled:false
          },
          plugins: {
            datalabels: {
              formatter: (value) => {
                return value + '%';
              },
            },
          },
        }
      });
      if(res.showScopeImpactDtos[0].impact+res.showScopeImpactDtos[1].impact+res.showScopeImpactDtos[2].impact==0.0){
        this.chart = new Chart('canvas', {
          type: 'doughnut',
          data: {
            labels: [""],
            datasets: [
              { 
                data: [1],
                backgroundColor: ['#cccdcf'],
                fill: false
              },
            ]
          },
          options: {
            aspectRatio: 1,
            legend: {
              display: false
            },
            tooltips:{
              enabled:false
            },
            plugins: {
              datalabels: {
                formatter: (value) => {
                  return value + '%';
                },
              },
            },
          }
        });
      }
    });
    
  }

  public downloadSummary(_companyassessment){
    const canvas = html2canvas(this.divToCapture.nativeElement, {
      onclone: (document: Document) => {
        const div = document.getElementById('pdf-snapshot-render');
        
      },
    }).then((canvas)=>{
      const screenshotImageBase64 = canvas.toDataURL('image/png');
      this.companyAssessmentService.downloadAssessmentResults(this._companyassessmentId,{exportedImage:screenshotImageBase64}).subscribe(ans=>{
        this.screeningService.DownloadFile(ans, "result summary.pdf", 'application/pdf');
      });
    });
  }

  mouseDown = false;

  startX: any;

  scrollLeft: any;

  @ViewChild('parent') slider: ElementRef;

  startDragging(e, flag) {
    this.mouseDown = true;
    this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
    this.scrollLeft = this.slider.nativeElement.scrollLeft;
  }
  stopDragging(e, flag) {
    this.mouseDown = false;
  }
  moveEvent(e) {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - this.slider.nativeElement.offsetLeft;
    const scroll = x - this.startX;
    this.slider.nativeElement.scrollLeft = this.scrollLeft - scroll;
  }


}


