<div class="share-overview-container">
    <div class="header">
        <img src="../../../assets/share.svg" alt="">
        <label>Share</label>
    </div>
    <div class="dynamic-share-container">
        <div class="tabs-container">
            <div class="tabs-header-container">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs">
                    <li [ngbNavItem]="1" [destroyOnHide]="true">
                        <a ngbNavLink [ngClass]="{'nav-button': active!=1,'nav-button-active': active==1}" (click)="changeProjectStatus()">
                            <label class="nav-text">My projects</label>
                            <div class="navlink"></div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="sides-container">
                                <div class="left-side">
                                    <app-my-project (newItemEvent)="getReflowidFromMyProject($event)"
                                        (projectEvent)="addItem($event)" [shareAction]="sharingAction"></app-my-project>
                                </div>
                                <div class="right-side">
                                    <div *ngIf="selectedProject==null" class="no-selected">
                                        <img src="../../../assets/no_project_selected.svg" alt="" class="not-selected-image"/>
                                        <label class="not-selected-message">Please select a project for more details</label>
                                    </div>
                                    <app-project *ngIf="active==1 && selectedProject!=null" [item]="selectedProject"></app-project>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink [ngClass]="{'nav-button': active!=2,'nav-button-active': active==2}" (click)="changeProjectStatus()">
                            <label class="nav-text">Shared projects</label>
                            <div class="navlink"></div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="sides-container">
                                <div class="left-side">
                                    <app-shared-with-me (sharedFolderEvent)="sharedModalActions($event)" (selectProject)="addItem($event)">
                                    </app-shared-with-me>
                                </div>
                                <div class="right-side">
                                    <div *ngIf="selectedProject==null" class="no-selected">
                                        <img src="../../../assets/no_project_selected.svg" alt="" class="not-selected-image"/>
                                        <label class="not-selected-message">Please select a project for more details</label>
                                    </div>
                                    <app-project-overview *ngIf="active==2 && selectedProject!=null" [item]="selectedProject"></app-project-overview>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink [ngClass]="{'nav-button': active!=3,'nav-button-active': active==3}" (click)="changeProjectStatus()">
                            <label class="nav-text">Request data</label>
                            <div class="navlink"></div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="sides-container">
                                <div class="left-side">
                                    <app-request-data (sharedFolderEvent)="sharedModalActions($event)" (selectProject)="addItem($event)">
                                    </app-request-data>
                                </div>
                                <div class="right-side">
                                    <div *ngIf="selectedProject==null" class="no-selected">
                                        <img src="../../../assets/no_project_selected.svg" alt="" class="not-selected-image"/>
                                        <label class="not-selected-message">Please select a project for more details</label>
                                    </div>
                                    <app-request-project-overview *ngIf="active==3 && selectedProject!=null" [item]="selectedProject"></app-request-project-overview>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                <div class="element-header" *ngIf="active==1" style="padding-right: 5px;
                padding-bottom: 4px;">
                    <div class="share-content" style="width: 94px;">
                        <em class="fa fa-share-alt dark-blue share-icon"></em>
                        <label class="share-title" (click)="showDetailsModal({target:false,toggle:true})">SHARE</label>
                    </div>
                </div>
                <div class="header-p" *ngIf="active==2">
                    <label class="viewmore-title" (click)="showViewMoreModal($event)">VIEW MORE</label>
                </div>
                <div class="header-p with-popper" *ngIf="active==3">
                    <div>&nbsp;</div>
                    <label class="viewmore-title" placement="bottom" triggers="mouseenter:mouseleave" [ngbPopover]="popContent" (click)="confirmShared()">CONFIRM SHARED</label>
                    <ng-template #popContent>
                        <div class="pop-content">
                            <div class="title-container">
                                <em class="icon-info bigger-info"></em>
                                <label>Confirm Shared</label>
                            </div>
                            <label class="description">Description:</label>
                            <p class="description-content">When you click on "Confirm Shared" you will complete the request and it will be deleted from the folder of your requests.</p>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div [ngbNavOutlet]="nav"></div>

        </div>
    </div>
    <div class="footer">
        <hr class="mt-3">
        <label class="footer-text">{{currentUserInformation?.date | date:'hh:mm - dd/MM/yyyy'}} - {{currentUserInformation?.email}}</label>
        <label class="footer-text">Session ID: {{currentUserInformation?.session}}</label>
    </div>
</div>
<app-shared-new-folder *ngIf="showNewFolderModal" (sharedFolderEvent)="sharedModalActions($event)">
</app-shared-new-folder>
<app-share-modal (toogleModal)="showSharedModal($event)" [projectToShare]="selectedProject"
    [showShareModal]="showSharedView" [sharingCode]="selectedSharingCode"  *ngIf="showSharedView" ></app-share-modal>
<app-project-details-share (toogleDetailsModal)="showDetailsModal($event)" [showDetailsView]="showDetailsView"
    *ngIf="showDetailsView" [item]="selectedProject"></app-project-details-share>
<app-shared-project-details (toogleDetailsModal)="showViewMoreModal($event)" [item]="selectedProject" [showDetailsView]="showMoreView"
    *ngIf="showMoreView"></app-shared-project-details>
