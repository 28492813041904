<div class="reflow-container rc-ss">
    <h1 class="title">
        Project setup
    </h1>
    <form [formGroup]="screeningToSetup" autocomplete="off">
        <div class="section1">
            <div class="form-group">
                <p class="p-heading"
                    [ngClass]="{ 'label-witherror':(submitted && screeningToSetup.controls.name?.errors?.required)}">
                    Name of project<label class="bullet">*</label>
                </p>
                <input type="text" [attr.disabled]="disable?'':null" [ngClass]="{
                    'witherror':(submitted && screeningToSetup?.controls.name?.errors?.required)===true,
                    'withsccess':screeningToSetup?.controls?.name?.errors===null
                  }" class="form-control" id="name" name="name" formControlName="name" required>
                <label *ngIf="submitted && screeningToSetup?.controls.name?.errors?.required" class="text-alert">Name of
                    screening required.</label>
            </div>
            <div class="form-group">
                <p class="p-heading"
                    [ngClass]="{ 'label-witherror':(submitted && screeningToSetup.controls.name?.errors?.required)}">
                    Select or type in your industry<label class="bullet">*</label>
                </p>
                <div class="form-group select">
                    <input [attr.disabled]="disable?'':null" class="form-control" type="text" id="dropowners"
                        formControlName="industry" [(ngModel)]="searchText" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" autocomplete="off" />
                    <div class="dropdown-menu" aria-labelledby="dropowners">
                        <button class="dropdown-item" type="button">
                        </button>
                        <div *ngFor="let industryDto of industries | filterPipe:searchText">
                            <button class="dropdown-item" type="button" (click)="selectIndustry(industryDto)">
                                <label class="drop-down-label">{{industryDto}}</label>
                            </button>
                            <div class="dropdown-divider"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h2 class="title-h2">
            Product specification
        </h2>
        <p class="product-description">
            Please describe the focal product of this assessment project and provide the weight and lifespan of one unit
            of product.
        </p>
        <div formGroupName="product">
            <div class="section2">
                <div class="form-group">
                    <p class="p-heading" [ngClass]="{ 'label-witherror':(submitted && frm.name?.errors?.required)}">
                        Name<label class="bullet">*</label>
                    </p>
                    <input [attr.disabled]="disable?'':null" [ngClass]="{
                        'witherror':(submitted && frm.name?.status!=='Valid')===true,
                        'withsccess':frm.name?.errors?.errors===null
                      }" type="text" class="form-control" id="name" name="name" formControlName="name">
                    <label *ngIf="submitted &&frm.name?.errors?.required" class="text-alert">Name of
                        product
                        required.</label>
                </div>
                <div class="form-group mt">
                    <p class="p-heading" [ngClass]="{ 'label-witherror':(submitted && frm.type?.errors?.required)}">
                        Type<label class="bullet">*</label>
                    </p>
                    <input [attr.disabled]="disable?'':null" [ngClass]="{
                        'witherror':(submitted && frm.type?.errors?.required)===true,
                        'withsccess':frm.type?.errors===null
                      }" type="text" class="form-control" id="type" name="type" formControlName="type" required>
                    <label *ngIf="submitted &&frm.type?.errors?.required" class="text-alert">Type of
                        product
                        required.</label>
                </div>
                <div class="row mt">
                    <div class="col-6">
                        <div class="form-group">
                            <p class="p-heading"
                                [ngClass]="{ 'label-witherror':(submitted && frm.weight?.errors?.required)}">
                                Weight<label class="bullet">*</label>
                                <ng-template #popupContent>
                                    <div class="pop-container">
                                        <div class="pop-header d-flex">
                                            <em class="icon-info bigger-info"></em>
                                            <label class="pop-title">
                                                Weight
                                            </label>
                                        </div>
                                        <p class="description-title">
                                            <strong>Description :</strong>
                                        </p>
                                        <p class="weigth-description">
                                            Weigth should account for the total product's weight (including packaging)
                                        </p>
                                    </div>
                                </ng-template>
                                <em class="icon-info hovered select-item-icon" animation='false' container="body"
                                    [ngbPopover]="popupContent" triggers="hover" placement="right"></em>
                            </p>
                            <input [attr.disabled]="disable?'':null" [ngClass]="{
                                'witherror':(submitted && frm?.weight?.errors?.required)===true,
                                'withsccess':frm?.weight?.errors===null
                              }" type="text" class="form-control" id="weight" name="weight" formControlName="weight"
                                required>
                            <span class="unit">kg</span>
                            <label *ngIf="submitted &&frm?.weight?.errors?.required" class="text-alert">Weight of
                                product
                                required.</label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <p class="p-heading"
                                [ngClass]="{
                                    'label-witherror': (submitted && frm.lifespan?.errors?.required) ||
                                    (submitted && frm.lifespan?.errors?.lifespanZero)
                                }">
                                Lifespan<label class="bullet">*</label>
                                <ng-template #popupContent1>
                                    <div class="pop-container">
                                        <div class="pop-header d-flex">
                                            <em class="icon-info bigger-info"></em>
                                            <label class="pop-title">Lifespan</label>
                                        </div>
                                        <p class="description-title">
                                            <strong>Description :</strong>
                                        </p>
                                        <p class="weigth-description">
                                            The lifespan is the time interval from when a product is launched to when
                                            its usefulness has ceased.
                                        </p>
                                    </div>
                                </ng-template>
                                <em class="icon-info hovered select-item-icon" animation="false" container="body"
                                    [ngbPopover]="popupContent1" triggers="hover" placement="right"></em>
                            </p>
                            <input [attr.disabled]="disable ? '' : null" [ngClass]="{
                                'witherror': (submitted && frm.lifespan?.errors?.required) ||
                                (submitted && frm.lifespan?.errors?.lifespanZero),
                                'withsccess': frm.lifespan?.errors === null
                              }" type="number" class="form-control" id="lifespan" name="lifespan" formControlName="lifespan"
                                required>
                            <span class="unit">years</span>
                            <label *ngIf="submitted && frm?.lifespan?.errors?.required" class="text-alert">
                                Lifespan of product required.
                            </label>
                            <label *ngIf="submitted && frm?.lifespan?.errors?.lifespanZero" class="text-alert">
                                Lifespan cannot be 0.
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <h2 class="title-h2">
                Product description
            </h2>
            <p class="product-description">
                The product description in terms of function and application is important for achieving good
                documentation, while necessary for establishing a fair comparison basis, where the compared products
                share the same function.
            </p>
            <div class="row">

                <div class="col-md1">
                    <img src="../../../../../assets/tip_icon@4x.png" style="width: 15px;
                    height: 16px;margin-left:16px;" alt="">
                </div>
                <div class="col-md-11">
                    <p class="product-description-tip">
                        <strong>Tips :</strong> You can also include information about the product’s performance or
                        average usage profiles when relevant.
                    </p>
                </div>

            </div>

            <div class="section2">
                <div class="form-group">
                    <p class="p-heading" [ngClass]="{ 'label-witherror':(submitted && frm.quantity?.errors?.required)}">
                        Briefly describe your product function(s)<label class="bullet">*</label>
                    </p>
                    <p class="product-description">
                        Example of function: To brew on average 4 cups of coffee per use and keep it hot for a minimum
                        of 30 min daily.
                    </p>
                    <input [attr.disabled]="disable?'':null" [ngClass]="{
                        'witherror':(submitted && frm.quantity?.errors?.required)===true,
                        'withsccess':frm.quantity?.errors===null
                      }" type="text" class="form-control" id="quantity" name="quantity" formControlName="quantity"
                        required>
                    <label *ngIf="submitted &&frm.quantity?.errors?.required" class="text-alert">This field is
                        required.</label>
                </div>
                <div class="form-group mt">
                    <p class="p-heading"
                        [ngClass]="{ 'label-witherror':(submitted && frm.durability?.errors?.required)}">
                        Briefly describe your product application(s)<label class="bullet">*</label>
                    </p>
                    <p class="product-description">
                        Example of application: In a household.
                    </p>
                    <input [attr.disabled]="disable?'':null" [ngClass]="{
                        'witherror':(submitted && frm.durability?.errors?.required)===true,
                        'withsccess':frm.durability?.errors===null
                      }" type="text" class="form-control" id="durability" name="durability"
                        formControlName="durability" required>
                    <label *ngIf="submitted &&frm.durability?.errors?.required" class="text-alert">This field is
                        required.</label>
                </div>
                <div class="form-group mt">
                    <p class="p-heading"
                        [ngClass]="{ 'label-witherror':(submitted && frm.comment?.errors?.required)}">
                        Comments :
                    </p>
                    <p class="product-description">
                        Example of limitations or something that need to be part of the PDF:
                    </p>
                    <div class="text-area-holder">
                    <textarea style="padding-top: 20px;" class="form-control" formControlName="comment" #comment id="comment" rows="3" maxlength="400" data-placeholder="my placeholder"></textarea>
                    <br>
                    <p class="text-count">{{comment.value.length}}/400 </p> 
                    </div>
                    <label *ngIf="submitted &&frm.comment?.errors?.required" class="text-alert">This field is
                        required.</label>
                </div>

            </div>
        </div>
    </form>
    <div class="footerContainer" style="float: right;display: block;">
        <div class="buttons custom-marg">
            <button class="simple-button" (click)="save()" *ngIf="!disable">
                <label>
                    Save and close
                </label>
            </button>
            <button class="big-turq" (click)="next()">
                <label class="label-next">
                    Next
                </label>
            </button>
        </div>

    </div>
    <div *ngIf="showAlert" class="alert alert-danger alert-dismissible fade show">
        <button type="button" class="close" data-dismiss="alert">&times;</button>
        <strong>Error: </strong>Fill out the missing field(s) to continue
    </div>
</div>
<p class="screening-id">Project ID: {{screening?.reflowId}}</p>