<div class="overview-container">

  <div class="infomation-header">
    <div class="row">
      <div class="block-icon"><em class="icon-info bigger-info"></em></div>
      <div class="block-text">
        <p class="information-text text-margin">
          The shared with me folder contains all of the calculations that other companies have shared with you. Some of
          the shared calculations may have some connections to your solution/products and therefore you can make use of
          those directly inside your projects.</p>
      </div>
    </div>
  </div>
  <hr>

  <div class="search-container">
    <div class="search-box" ngbDropdown >
      <em class="icon-search icon-search-left"></em>
      <input
        type="text"
        placeholder="Search project"
        autocomplete="off"
        class="input-search"
        [(ngModel)]="searchText"
        (ngModelChange)="makeSearch()"
        (focus)="searchTriggered=true"
        (blur)="searchTriggered=false"
        ngbDropdownToggle
      >
      <em class="icon-close close-search" style="top: 0px; right:0px;font-size: 23px;" *ngIf="searchText!=''" (click)="searchText='';searchList=[]"></em>
      <div class="icon-search-right-container"  *ngIf="searchTriggered||searchText!=''">
        <em class="icon-search icon-search-right"></em>
      </div>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu" *ngIf="searchText!=''">
        <ng-container *ngFor="let item of searchList" >
          <button class="dd-item" ngbDropdownItem (click)="selectingProject(item)">
            <img src="../../../../assets/pi_draft_projects.svg" alt="">
            {{item?.sharedProjectName}}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="dynamic-content-container">

    <div class="folders-container">
      <div class="top-container" style="border-right: none;">
        <div class="operation  ml-40 operation-hover" (click)="importModalActions(null)" style="margin-left: -2px;">
          <img src="../../../../assets/Group 161666.svg" alt="" style="width:33px; margin-top: 3px ;">
          <label for="">Import project</label>
        </div>
        <div class="operation ml-40 operation-hover" (click)="openNewFolderModalFunction()" *ngIf="false">
          <img src="../../../../assets/new_folder.svg" alt="">
          <label for="">New folder</label>
        </div>
      </div>
      <div class="folders-tree-container">
        <div class="folders-tree">
          <hr class="root-vr">
          <div class="folder" (click)="selectFolder('My projects')"
            [ngClass]="{'engaged-folder':selectedFolder=='My projects'}" data-toggle="collapse"
            data-target="#collapseMyProjects" aria-expanded="false" aria-controls="collapseMyProjects">
            <img
              [src]="selectedFolder=='root'?'../../../../assets/fm_open_folder@2x.png':'../../../../assets/fm_closed_folder@2x.png'"
              alt="" class="folder-state">
            <label>My projects</label>
            <img *ngIf="!canShowFolders" class="folder-loading"
            src="../../../assets/Loading-icon-animation.svg" alt="" />
          </div>
          <div class="collapse" id="collapseMyProjects">
            <hr class="root-vr sub-vr">
            <div class="subfolders">
              <ng-container *ngFor="let subfolder of myPorjectsSubfolders">
                <div class="folder ml-37" (click)="selectFolder(subfolder?.folderName)"
                  [ngClass]="{'engaged-folder':selectedFolder==subfolder?.folderName}">
                  <hr class="subfolder-treelink">
                  <img
                    [src]="selectedFolder==subfolder?.folderName?'../../../../assets/fm_open_folder@2x.png':'../../../../assets/fm_closed_folder@2x.png'"
                    alt="" class="folder-state">
                  <div class="d-flex justify-content-between ">
                    <label>{{subfolder?.folderName}}</label>
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </div>
                </div>
              </ng-container>

            </div>
          </div>
          <div *ngIf="false" class="folder" (click)="selectFolder('root1')"
            [ngClass]="{'engaged-folder':selectedFolder=='root1'}" data-toggle="collapse"
            data-target="#collapseMyProjects1" aria-expanded="false" aria-controls="collapseMyProjects1">
            <img
              [src]="selectedFolder=='root1'?'../../../../assets/fm_open_folder@2x.png':'../../../../assets/fm_closed_folder@2x.png'"
              alt="" class="folder-state">
            <label>Data subscribers</label>
          </div>
          <div class="collapse" id="collapseMyProjects1">
            <hr class="root-vr sub-vr">
            <div class="subfolders">
              <div class="folder ml-37" (click)="selectFolder('Pumps1')"
                [ngClass]="{'engaged-folder':selectedFolder=='Pumps1'}">
                <hr class="subfolder-treelink">
                <img
                  [src]="selectedFolder=='Pumps1'?'../../../../assets/fm_open_folder@2x.png':'../../../../assets/fm_closed_folder@2x.png'"
                  alt="" class="folder-state">
                <div class="d-flex justify-content-between ">
                  <label>Pumps</label>
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </div>
              </div>
              <div class="folder ml-37" (click)="selectFolder('Turbo1')"
                [ngClass]="{'engaged-folder':selectedFolder=='Turbo1'}">
                <hr class="subfolder-treelink">
                <img
                  [src]="selectedFolder=='Turbo1'?'../../../../assets/fm_open_folder@2x.png':'../../../../assets/fm_closed_folder@2x.png'"
                  alt="" class="folder-state">
                <div class="d-flex justify-content-between ">
                  <label>Pumps</label>
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </div>
              </div>
              <div class="folder ml-37" (click)="selectFolder('Cooler1')"
                [ngClass]="{'engaged-folder':selectedFolder=='Cooler1'}">
                <hr class="subfolder-treelink">
                <img
                  [src]="selectedFolder=='Cooler1'?'../../../../assets/fm_open_folder@2x.png':'../../../../assets/fm_closed_folder@2x.png'"
                  alt="" class="folder-state">
                <div class="d-flex justify-content-between ">
                  <label>Pumps</label>
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div class="buffer"></div>
      </div>
    </div>
    <div class="projects-container">
      <div class="top-container br-tr" style="border-left: solid 1px #ddd;">
        <div class="operation" (click)="filter('private')" *ngIf="false">
          <div class="checkbox">
            <i class="fa fa-check" aria-hidden="true" *ngIf="filterParameter=='private'"></i>
          </div>
          <label for="">Privately shared</label>
        </div>
        <div class="operation ml-15" (click)="filter('public')" *ngIf="false">
          <div class="checkbox">
            <i class="fa fa-check" aria-hidden="true" *ngIf="filterParameter=='public'"></i>
          </div>
          <label for="">Publicly shared</label>
        </div>

      </div>
      <div class="projects-list-container">
        <div class="projects-list">
          <ng-container *ngFor="let project of projects;let i=index">
            <div class="project-item" [ngClass]="{'engaged-project': selectedProject==project}"
              (click)="selectingProject(project);">
              <div class="project">

                <img src="../../../../assets/pm_completed_projects@2x.png" alt="">
                <label>{{project.sharedProjectName}}</label>
              </div>
              <img src="../../../../assets/private_share.svg" class="project-icon">
            </div>
          </ng-container>
        </div>
        <div class="buffer"></div>
      </div>

    </div>
  </div>
</div>
<app-import-project *ngIf="showImportModal" (importPorjectEvent)="importModalActions($event)"></app-import-project>
