import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HelperService } from './helper.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyAssessmentService {

  environmentUrl = "Debug api";
  constructor(
    private http: HttpClient,
    private helperService: HelperService
  ) {
    this.environmentUrl = environment.apiUrl;
  }

  public getYears(companyId){
    return this.http.get<any>(
      `${this.environmentUrl}/api/company-assessment/`+companyId+`/years`,
      this.helperService.headers()
    );
  }

  public createCompanyAssessment(companyAssessmentDto){
    return this.http.post<any>(
      `${this.environmentUrl}/api/company-assessment/new`,
      companyAssessmentDto,
      this.helperService.headers()
    );
  }

  public updateCompanyProfile(updateCompanyProfileDto){
    return this.http.post<any>(
      `${this.environmentUrl}/api/company-assessment/updateCompanyProfile`,
      updateCompanyProfileDto,
      this.helperService.headers()
    );
  }

  public updateYearSelection(id,updateYearDto){
    return this.http.put<any>(
      `${this.environmentUrl}/api/company-assessment/updateYear/${id}`,
      updateYearDto,
      this.helperService.headers()
    );
  }

  public getAssessmentsByCompanyId(companyId){
    return this.http.get<any>(
      `${this.environmentUrl}/api/company-assessment/${companyId}`,
      this.helperService.headers()
    );
  }

  public getCountries(){
    return this.http.get<any>(
      `${this.environmentUrl}/api/company-assessment/countries`,
      this.helperService.headers()
    );
  }

  public getcountiesHotelFactor(){
    return this.http.get<any>(
      `${this.environmentUrl}/api/company-assessment/countries-hotel-factor`,
      this.helperService.headers()
    );
  }

  public getAssessmentsById(companyId,assessmentId){
    return this.http.get<any>(
      `${this.environmentUrl}/api/company-assessment/getAssessmentsById/${companyId}/${assessmentId}`,
      this.helperService.headers()
    );
  }

  public getGroupMaterial(companyAssessmentId,group){
    return this.http.get<any>(
      `${this.environmentUrl}/api/company-assessment/data-collection/${companyAssessmentId}/${group}`,
      this.helperService.headers()
    );
  }

  public addDataCollectionProcess(companyAssessmentId,categoryId,data){
    return this.http.post<any>(
      `${this.environmentUrl}/api/company-assessment/${companyAssessmentId}/data-collection/${categoryId}`,
      data,
      this.helperService.headers()
    );
  }


  public getCompanyPofilByAssessmentId(compassId){
    return this.http.get<any>(
      `${this.environmentUrl}/api/company-assessment/getCompanyPofilByAssessmentId/${compassId}`,
      this.helperService.headers()
    );
  }

  public getcategoriesByCompanyAssessmentId(compassId){
    return this.http.get<any>(
      `${this.environmentUrl}/api/company-assessment/${compassId}/categories`,
      this.helperService.headers()
    );
  }

  public updateCategoryStatus(companyAssessmentId,updateCategoryStatusDto){
    return this.http.post<any>(
      `${this.environmentUrl}/api/company-assessment/${companyAssessmentId}/updateCategory/${updateCategoryStatusDto.categoryId}`,
      updateCategoryStatusDto,
      this.helperService.headers()
    );
  }

  public getDataCollectionByType(companyAssessmentId,categoryId){
    return this.http.get<any>(
      `${this.environmentUrl}/api/company-assessment/${companyAssessmentId}/getProcesses/${categoryId}`,
      this.helperService.headers()
    );
  }

  public removeDataCollectionProcess(companyAssessmentId,categoryId,processId){
    return this.http.delete<any>(
      `${this.environmentUrl}/api/company-assessment/${companyAssessmentId}/data-collection/${categoryId}/remove-process/${processId}`,
      this.helperService.headers()
    );
  }

  public updateDataCollectionProcess(companyAssessmentId,categoryId,processId,UpdateDataCollectionProcessDto){
    return this.http.post<any>(
      `${this.environmentUrl}/api/company-assessment/${companyAssessmentId}/data-collection/${categoryId}/update-process/${processId}`,
      UpdateDataCollectionProcessDto,
      this.helperService.headers()
    );
  }

  public downloadAssessmentResults(companyAssessmentId,chartImage){
    const httpOptions:any= {
      responseType: 'blob',
        headers: new HttpHeaders({
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
        })
    };
    return this.http.post<any>(`${this.environmentUrl}/api/CompanyAssessmentExport/summary/` + companyAssessmentId,chartImage,httpOptions);
  }

  public getUncompletedCategories(companyAssessmentId){
    return this.http.get<any>(
      `${this.environmentUrl}/api/company-assessment/${companyAssessmentId}/uncompletedCategories`,
      this.helperService.headers()
    );
  }

  public calculateCompanyAssessment(companyAssessmentId){
    return this.http.get<any>(
      `${this.environmentUrl}/api/company-assessment/${companyAssessmentId}/calculate`,
      this.helperService.headers()
    );
  }

  public getCompanyAssessmentResult(companyAssessmentId){
    return this.http.get<any>(
      `${this.environmentUrl}/api/company-assessment/${companyAssessmentId}/result`,
      this.helperService.headers()
    );
  }

  public UpdateCompanyAssessmentAccess(updateCompanyAssessmentAccessDto){
    return this.http.post<any>(
      `${this.environmentUrl}/api/company-assessment/updateAccess`,
      updateCompanyAssessmentAccessDto,
      this.helperService.headers()
    );
  }
}
